import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import {
  FormControl,
  Stack,
  FormLabel,
  Grid,
  TableContainer,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import format from "date-fns/format";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import {
  BankTransaction,
  GetUsers,
  MutlipleAssignTxnUser,
  getBrokerBranchDetailsByBrokerBranchName,
  UploadFile,
} from "../../Services/api";
import instance from "../../redux/api";
import { BANK_STATEMENT_ROW_DATA } from "../../utils/config";
import DialogBox from "../common/dialog";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import {
  getAccountsList,
  getBrokerBranchesList,
  getCurrencyList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import "../bankTransactions/bankTransaction.css";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { AddUpload } from "./AddUpload";
import {
  numberFormatter,
} from "../../utils/generalFunctions";
import { customDateFormat, getPaginationCount } from "../CashAllocation/utils";

const dialogStyle = {
  maxWidth: "60vw",
  width: "60vw",
  margin: "auto",
};

export default function UploadFilesConatiner() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);

  const accountsList = useSelector(
    (state) => state?.bankStatmentTransactions?.accountsList
  );
  const brokerBranches = useSelector(
    (state) => state?.bankStatmentTransactions?.brokerBranchesList
  );
  const dropdownValuesCurreny = useSelector(
    (state) => state?.bankStatmentTransactions?.currencyList
  );

  const dispatch = useDispatch();

  // Local State
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpenAddBankTxnDialog, setIsOpenAddBankTxnDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [assignType, setAssignType] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInputValue, setDialogInputValue] = useState("");
  const [
    currentPaymentReferenceIndex,
    setCurrentPaymentReferenceIndex,
  ] = useState(0);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [uploadedStmtId, setUploadedStmtId] = useState();

  const [rows, setRows] = useState([
    { ...BANK_STATEMENT_ROW_DATA, Bank_Account_Name_Entity: selectedOption },
  ]);
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );
  const [
    currentUpdatedFiledsInEditMode,
    setCurrentUpdatedFiledsInEditMode,
  ] = useState({});

  //bank statement transaction search
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Receiving_Bank_Account: "",
    uploaded_status: "",
  });

  const [currentRow, setCurrentRow] = useState("");
  const [txnMode, setTxnMode] = useState("VIEW");

  //File upload
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);

  const handleFileUpLoadDialog = () => {
    setIsFileUploadDialogOpen(true);
  };

  //For pagination
  const [defaultPage, setDefaultPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [isBankAccountNoDeSelected, setIsBankAccountNoDeSelected] = useState(false);
  const [isUploadedStatusSelected, setIsUploadedStatusSelected] = useState(false);

  const [assigningToUserDetails, setAssigningToUserDetails] = useState({});

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const uploadedStatusList = [
    { id: 1, uploaded_status: "uploaded" },
    { id: 1, uploaded_status: "rejected" },
  ];

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  const handleBankTnxSelectAccountDropdown = (name, value, reason) => {
    if (reason === "clear") {
      setIsBankAccountNoDeSelected(true);
    }
    setIsBankAccountNoDeSelected(false);
    setSearchFieldsPrams({
      ...searchFieldsPrams,
      [name]: value ?? "",
    });
  };

  const handleClearSearchList = async () => {
    setIsClearPerformed(true);
    setIsSearchPerformed(false);
    // clear fields
    setSearchFieldsPrams({
      Receiving_Bank_Account: "",
      uploaded_status: "",
    });
    setFromDateValue("");
    setToDateValue("");
  };

  useEffect(() => {
    // Fetch users data from an API
    instance
      .get(GetUsers)
      .then((data) => {
        setUsers(data.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleSubmitAssing = async () => {
    const assigningToInfo = users?.filter(
      (item) => item?.user_name === selectedUser
    );
    const assigningToId = assigningToInfo[0]?.id;

    try {
      if (assignType === "ASSIGN") {
        const response = await instance.patch(
          `${UploadFile}${uploadedStmtId}/`,
          { id: uploadedStmtId, allocated_analyst_id: assigningToId }
        );
        // Check if the response is successful (status code 2xx)
        if (response) {
          handleCloseDialog();
          loadData();
          // Display success notification
          toast.success("Submission successful", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // Display error notification if response status is not in the success range
          toast.error("Submission failed. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      } else if (assignType === "UPDATE_ASSIGNMENT_DROPDOWN_VALUE") {
        handleCloseDialog();
        setAssigningToUserDetails({ ...assigningToInfo[0] });

        // Update Assignement column only for Selected Rows
        let rowsToUpdate = [...rows];
        rowsToUpdate.forEach((element) => {
          const { id } = element;
          if (selectedRows?.findIndex((item) => item.id === id) !== -1) {
            return (element.Assigned_Users[0] = assigningToInfo[0]);
          }
          return element;
        });
        // update Rows
        setRows([...rowsToUpdate]);
      }
    } catch (err) {
      // Display error notification for request failure
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleAssignButtonClick = (params, type) => {
    setSelectedUser("");
    setDialogOpen(true);
    setUploadedStmtId(params.row.id);
    setAssignType(type);
  };

  const handleAssignButtonInUploadDetails = () => {
    setSelectedUser("");
    setDialogOpen(true);
    setAssignType("UPDATE_ASSIGNMENT_DROPDOWN_VALUE");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // handle "Select All" checkbox
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectedRows(isChecked ? [...rows] : []);
    setSelectAll(isChecked);
  };

  const handleCheckboxChange = (row, isChecked) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        const newRows = [...prevSelectedRows, row];
        if (newRows.length === rows.length) {
          setSelectAll(isChecked);
        }
        return newRows;
      } else {
        setSelectAll(isChecked);
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
      }
    });
  };

  useEffect(() => {
    // Fetch Banks List
    dispatch(getAccountsList());
    // Fetch Broker branches
    dispatch(getBrokerBranchesList());
    // Fetch Currency Details
    dispatch(getCurrencyList());
  }, []);

  const handleBankAccountDropdownChange = (value, reason = "") => {
    if (reason === "clear") {
      setIsUploadedStatusSelected(false);
      setSelectedOption(value);
    } else {
      setIsUploadedStatusSelected(true);
      setSelectedOption(value);
    }
  };

  const handleActionButton = async (currentRowData, currentTxnMode) => {
    if (currentRowData.row.uploaded_status === "uploaded") {
      try {
        const id = currentRowData.row.id;
        console.log("currrent row", currentRowData);
        setUploadedStmtId(id);

        const response = await instance.get(
          `${BankTransaction}?br_id=${id}&skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
          }`
        );

        setRows(response.data?.data);

        setCurrentRow(currentRowData);
        setTxnMode(currentTxnMode);
        setIsOpenAddBankTxnDialog(true);
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      toast.error("Error: File not uploaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      ...BANK_STATEMENT_ROW_DATA,
      Bank_Account_Name_Entity: selectedOption,
    }));
    setRows(emptyRows);

    setOpenResetDialog(false);
    handleBankAccountDropdownChange("");
  };

  const handleDeleteConfirmed = async () => {
    try {
      // Send DELETE request to your API
      const response = await instance.delete(UploadFile + idToDelete);

      // Check if the deletion was successful
      if (response.status === 200) {
        // If successful, show a success toast
        toast.success("Transaction deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpen(false);
        setIdToDelete(null);

        loadData();
        // If needed, update the state to reflect the deletion
        const updatedRows = rows.filter((row) => row.id !== idToDelete);
        setRows(updatedRows);
      } else {
        // If deletion was not successful, show an error toast
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      // If an error occurs during deletion, show an error toast
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleDeleteCancelled = () => {
    setOpen(false);
    setIdToDelete(null);
  };

  const columns = [
    {
      field: "uploaded_date",
      headerName: "Uploaded Date",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => <span>{customDateFormat(params.row.uploaded_date)}</span>
    },
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      minWidth: "25%",
      flex: 1,
    },
    {
      field: "bank_account_no",
      headerName: "Account No",
      headerAlign: "center",
      flex: 0.8,
    },
    {
      field: "bankName",
      headerName: "Bank Name",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.bank_details?.bank_name,
      renderCell: (params) => <>{params.row?.bank_details?.bank_name ?? ""}</>,
    },
    {
      field: "file_date",
      headerName: "File Date",
      flex: 0.8,
      headerAlign: "center",
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        // Format the date using date-fns or any other date formatting library
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
    {
      field: "uploaded_status",
      headerName: "Uploaded Status",
      headerAlign: "center",
      minWidth: "10%",
      flex: 0.9,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      description: "This column has a value getter and is not sortable.",
      minWidth: "10%",
      flex: 0.6,
      valueGetter: (params) => params?.row?.uploaded_status,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.uploaded_status?.toLowerCase() === "uploaded" ? (
              <VisibilityIcon
                style={{ marginRight: 8, cursor: "pointer" }}
                onClick={() => handleActionButton(params, "VIEW")}
                titleAccess={params?.row?.uploaded_status?.toLowerCase()}
              />
            ) : (
              <PersonOutlineIcon
                style={{ marginRight: 8, cursor: "pointer" }}
                onClick={() => handleAssignButtonClick(params, "ASSIGN")}
                titleAccess={"Assign"}
              />
            )}
          </>
        );
      },
    },
    {
      field: "user_name",
      headerName: "Assign To",
      headerAlign: "center",
      minWidth: "10%",
      flex: 0.8,
      valueGetter: (params) => params?.row?.allocated_analyst_id?.user_name,
      renderCell: (params) => (
        <div>{params?.value ?? ""}</div>
      ),
    },
    {
      field: "error_message",
      headerName: "Description",
      headerAlign: "center",
      align: "right",
      flex: 1,
    },
  ];

  const handleDialogClose = () => {
    setIsOpenAddBankTxnDialog(false);
    setSelectedRows([]);
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { Receiving_Bank_Account, uploaded_status } = searchFieldsPrams;

    if (Receiving_Bank_Account) {
      getSearchQueryParams = `${getSearchQueryParams}&accountNo=${Receiving_Bank_Account}`;
    }

    if (uploaded_status) {
      getSearchQueryParams = `${getSearchQueryParams}&uploadedStatus=${uploaded_status}`;
    }

    let formattedFromDateValue = "",
      formattedToDateValue = "";

    if (fromDateValue && toDateValue) {
      formattedFromDateValue = format(fromDateValue, "dd/MM/yyyy");
      formattedToDateValue = format(toDateValue, "dd/MM/yyyy");
      getSearchQueryParams = `${getSearchQueryParams}&fromDate=${formattedFromDateValue}&toDate=${formattedToDateValue}`;
    }

    return getSearchQueryParams;
  }

  const loadSearchTxnData = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      queryParams = `${queryParams}&uploaded_statements=1`;

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${UploadFile}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ? response?.data?.data : [],
          page: 1,
        }));
        setIsSearchPerformed(true);
        setIsClearPerformed(false);
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      queryParams = `${queryParams}&uploaded_statements=1`;

      const resp = await instance.get(`${UploadFile}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (resp.status === 200) {
        setPageState((old) => ({
          ...old,
          total: resp?.data?.count,
          data: resp?.data?.data ? resp?.data?.data : [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: resp?.data?.count,
          data: [],
        }));
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      console.error("err", err);
    }
  };

  const handleSubmitButtonEnable = () => {
    let isDisabled = true;
    if (selectedRows?.length > 0) {
      selectedRows.forEach((element) => {
        const filteredList = rows.filter((item) => item.id === element?.id);
        if (filteredList?.length > 0) {
          if (filteredList[0].Assigned_Users[0]?.user_name === selectedUser) {
            isDisabled = false;
          } else {
            isDisabled = true;
          }
        }
      });
    }
    return isDisabled;
  };

  const handleSubmit = async () => {
    // get selected user id
    const assigningToInfo = users?.filter(
      (item) => item?.user_name === selectedUser
    );
    const assigningToId = assigningToInfo[0]?.id;

    // req body
    const payload = {
      user_login_id: assigningToId,
      Bank_Transaction_Ids: selectedRows.map((row) => ({ id: row.id })),
    };
    try {
      let response = await instance.post(MutlipleAssignTxnUser, payload);
      // Check if the response is successful (status code 2xx)
      if (response.status >= 200 && response.status < 300) {
        handleDialogClose();
        loadData();
        // Display success notification
        toast.success("Submission successful", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // Display error notification if response status is not in the success range
        toast.error("Submission failed. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      // Display error notification for request failure
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      setRows(updatedRows);
      // Edit Mode
      if (editMode) {
        setCurrentUpdatedFiledsInEditMode({
          ...currentUpdatedFiledsInEditMode,
          [colName]: value,
        });
      }
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };

  const handleBrokerBranchDropdown = (index, colName, value, reason, event) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      // get broker_information (Add Mode)
      instance
        .post(getBrokerBranchDetailsByBrokerBranchName, {
          Broker_Branch_Name: value,
        })
        .then((response) => {
          updatedRows[index]["broker_information"] = response?.data?.id;
          setRows(JSON.parse(JSON.stringify(updatedRows)));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };

  const handlePaymentReferenceOpenDialog = (
    curindex,
    currentRowPaymentRefData
  ) => {
    setOpenDialog(true);
    setDialogInputValue(currentRowPaymentRefData);
    setCurrentPaymentReferenceIndex(curindex);
  };

  const handleCloseDialogs = () => {
    setOpenDialog(false);
  };

  const handleSavePaymentReference = () => {
    setOpenDialog(false);
    handleInputChange(
      currentPaymentReferenceIndex,
      "Payment_Reference",
      dialogInputValue
    ); // Pass the index parameter
  };

  const handleDialogInputChange = (e) => {
    setDialogInputValue(e.target.value);
  };

  const handleBlur = (index, colName, value) => {
    // If the value is float
    if (!Number.isInteger(value)) {
      switch (colName) {
        case "Bank_Charges":
        case "Bank_Exchange_Charges":
        case "Receivable_Amount":
          value = parseFloat(value).toFixed(2);
          break;
        case "Bank_Exchange_Rate":
          value = parseFloat(value).toFixed(6);
          break;
        default:
      }
    }

    const updatedRows = [...rows];
    updatedRows[index][colName] = value;
    setRows(updatedRows);
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Receiving_Bank_Account, uploaded_status } = searchFieldsPrams;
    if (
      Receiving_Bank_Account !== "" ||
      uploaded_status !== "" ||
      fromDateValue !== "" ||
      toDateValue !== ""
    ) {
      disabled = false;
    }
    return disabled;
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isBankAccountNoDeSelected || isClearPerformed || !isUploadedStatusSelected) {
      loadData();
    }
  }, [isBankAccountNoDeSelected, isClearPerformed, isUploadedStatusSelected]);

  useEffect(() => {
    if (!isBankAccountNoDeSelected) {
      loadData();
    }
  }, [pageState?.pageSize, pageState?.page]);

  // const handleViewDownloadClick=()=>{
  //   instance.get(`${getBankReconciliationView}`)
  //   .then(response=>console.log("res viw", response))
  // }
  return (
    <div style={Container}>
      <div>
        <div className="assignBankStatementBar">
          <span style={{ color: "#FF5A01", fontSize: "20px" }}>
            Uploaded Statements{" "}
          </span>
        </div>

        <Grid
          container
          className="bankStatementSearchContainer"
          xs={12}
          gap={"15px"}
          mt={"10px"}
        >
          <Grid
            item
            xs={2}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Account No</FormLabel>
            <CommonSelect
              placeholder="Select Account No"
              value={searchFieldsPrams?.Receiving_Bank_Account}
              handleChange={(event, value, reason) =>
                handleBankTnxSelectAccountDropdown(
                  "Receiving_Bank_Account",
                  value,
                  reason,
                  event
                )
              }
              options={
                accountsList?.map((option) => option?.account_number) || []
              }
            />
          </Grid>
          <Grid
            item
            xs={1.8}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Uploaded Status</FormLabel>
            <CommonSelect
              placeholder="Select Status"
              value={searchFieldsPrams?.uploaded_status}
              handleChange={(event, value, reason) =>
                handleBankTnxSelectAccountDropdown(
                  "uploaded_status",
                  value,
                  reason,
                  event
                )
              }
              options={
                uploadedStatusList?.map((option) => option?.uploaded_status) ||
                []
              }
            />
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"} gap={"2px"}>
            <FormLabel>File Period</FormLabel>
            <CustomDateRangePicker
              startDate={fromDateValue}
              setStartDate={setFromDateValue}
              endDate={toDateValue}
              setEndDate={setToDateValue}
              maxDate={new Date()}
            />
          </Grid>
          <Grid
            item
            display={"flex"}
            flexDirection={"row"}
            gap={"6px"}
            mt={"20px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={() => loadSearchTxnData()}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          height: "calc(100vh - 295px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          columns={columns}
          keepNonExistentRowsSelected
          startIcon={<AddIcon />}
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          pageSizeOptions
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
              setDefaultPage(1);
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='error_message']": {
              minWidth: toggle?.isOpen
                ? "164.150px !important"
                : "191px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Transactions
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
        <br />
      </div>

      <Dialog
        open={isOpenAddBankTxnDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          Bank File Upload Details
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon className="CloseIconStyle" />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <Grid display={"flex"} flexDirection={"column"}>
            <Card elevation={5} className="EditPageContainer">
              <Grid container className="EditpageContainerItems" spacing={3}>
                <Grid
                  item
                  xs={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Uploaded Date</FormLabel>
                  <TextField
                    value={customDateFormat(
                      currentRow?.row?.uploaded_date ?? ""
                    )}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Account</FormLabel>
                  <TextField
                    value={currentRow?.row?.bank_account_no ?? ""}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Bank Currency</FormLabel>
                  <TextField
                    value={rows[0]?.Bank_Currency_Code ?? ""}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Bank Name</FormLabel>
                  <TextField
                    value={rows[0]?.bank_details?.bank_name ?? ""}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Entity Name</FormLabel>
                  <TextField
                    value={rows[0]?.bank_details?.entity_number ?? ""}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Bank amount CR total</FormLabel>
                  <TextField
                    value={numberFormatter(currentRow?.row?.credit_amount ?? 0)}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                    className="right-align"
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Bank amount DB total</FormLabel>
                  <TextField
                    value={numberFormatter(currentRow?.row?.debit_amount ?? 0)}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                    className="right-align"
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Total Balance</FormLabel>
                  <TextField
                    value={numberFormatter(currentRow?.row?.total_amount ?? 0)}
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={true}
                    className="right-align"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Uploaded File</FormLabel>
                  <a
                    href={currentRow?.row?.file_name_hyperlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "blue",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <DownloadIcon /> {currentRow?.row?.file_name}
                  </a>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <div elevation={6} className="EditPageContainerTransactions">
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                <CommonButton
                  sx={{
                    background: "#FF5A01",
                    color: "white",
                    borderRadius: "20px",
                    padding: "5px 20px",
                    width: "91px",
                  }}
                  text="Assign"
                  handleClick={handleAssignButtonInUploadDetails}
                  disabled={selectedRows.length === 0}
                  hoverColor="#FF5A01"
                />
              </div>
            </div>

            <TableContainer style={{ maxHeight: "300px", marginTop: "10px" }}>
              <AddUpload
                selectAll={selectAll}
                handleInputChange={handleInputChange}
                handleSelectAll={handleSelectAll}
                rows={rows}
                // setRows={setRows}
                handleBlur={handleBlur}
                handleCheckboxChange={handleCheckboxChange}
                // handleBrokerBranchDropdown={handleBrokerBranchDropdown}
                // viewMode={viewMode}
                validationStatus={validationStatus}
                dropdownValuesCurreny={dropdownValuesCurreny}
                // brokerBranches={brokerBranches}
                // txnData={currentRow}
                handlePaymentReferenceOpenDialog={
                  handlePaymentReferenceOpenDialog
                }
                selectedRows={selectedRows}
              />
            </TableContainer>
          </div>
        </DialogContent>

        <DialogActions className="resetSubmit">
          <>
            <CommonButton
              text="Submit"
              handleClick={handleSubmit}
              hoverColor="#FF5A01"
              disabled={handleSubmitButtonEnable()}
            />
            <CommonButton
              className="addBankTransactionReset"
              text="Cancel"
              handleClick={handleDialogClose}
              disabled={editMode}
            />
          </>
        </DialogActions>
      </Dialog>

      <DialogBox
        title="Assign To"
        body={
          <FormControl style={{ width: "100%" }}>
            <label style={{ marginBottom: 10 }}>Assigning to</label>
            <CommonSelect
              name="assigning"
              value={selectedUser}
              handleChange={(event, value, reason) =>
                setSelectedUser(value, reason)
              }
              options={users?.map((option) => option?.user_name)}
            />
          </FormControl>
        }
        confirmText="Assign"
        isOpen={isDialogOpen}
        onCancel={handleCloseDialog}
        onConfirm={handleSubmitAssing}
        isDisableConfirm={selectedUser?.length > 0 ? false : true}
      />
      <Dialog open={openDialog} onClose={handleCloseDialogs}>
        <DialogTitle>Payment Reference</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogInputValue}
            onChange={handleDialogInputChange}
            className="PaymentReferenceDialogue"
            disabled={true}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseDialogs}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSavePaymentReference}
            hoverColor="#FF5A01"
            disabled={true}
          />
        </DialogActions>
      </Dialog>

      <DialogBox
        title="Delete Confirmation"
        body="Are you sure you want to delete this item?"
        confirmText="Delete"
        isOpen={open}
        onCancel={handleDeleteCancelled}
        onConfirm={handleDeleteConfirmed}
      />

      <DialogBox
        title="Reset Confirmation"
        body="Are you sure you want to reset?"
        confirmText="Yes"
        cancelText="No"
        isOpen={openResetDialog}
        onCancel={() => setOpenResetDialog(false)}
        onConfirm={handleReset}
      />
    </div>
  );
}
