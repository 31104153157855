import { configureStore } from '@reduxjs/toolkit'
import login from "./slice/loginSlice"
import usersList from './slice/usersListSlice'
import bankStatmentTransactions from './slice/bankStatmentTransactionsSlice'
import user from './slice/userSlice'
import allocateBankTransactions from './slice/AllocateBankTransactionsSlice'
import divisions from './slice/divisionsSlice'
import manageUser from './slice/manageUserSlice'
import toggleSideMenu from './slice/SideBarStateSlice'

export const store = configureStore({
    reducer: {
        login,
        user,
        usersList,
        bankStatmentTransactions,
        allocateBankTransactions,
        divisions,
        manageUser,
        toggleSideMenu,

    },
})